import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Profile } from '../../../core/admins/types'

import './styles.scss'

interface ProfilesModalProps {
  profiles: Profile[]
  onClose: () => void
}

const ProfilesModal: FC<ProfilesModalProps> = ({ profiles, onClose }) => {
  const { t } = useTranslation()
  return (
    <Modal show={true} onHide={onClose} className="ProfilesModal">
      <Modal.Header closeButton>
        <Modal.Title>{t('ProfilesModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {profiles.map((item) => (
          <div className="ProfilesModal__item" key={item.id}>
            {item.name}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button className="btn btn-primary" onClick={onClose}>
            {t('common.accept')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ProfilesModal
