import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import NotFound from '../pages/error/NotFound'
import Modules from '../pages/Modules'
import TenantDetail from '../pages/TenantDetail'
import UrlPage from '../pages/Url'
import LicensesPage from '../pages/Licenses'
import IdsConfigPage from '../pages/Ids'
import AdminsScreen from '../pages/Admins'

const TenantRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<TenantDetail />} />
      <Route path="/modules" element={<Modules />} />
      <Route path="/urls" element={<UrlPage />} />
      <Route path="/licenses" element={<LicensesPage />} />
      <Route path="/ids-config" element={<IdsConfigPage />} />
      <Route path="/admins" element={<AdminsScreen />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default TenantRouter
