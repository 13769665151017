import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Module, ModuleState, ToggleActivePayload } from './types'

const initialState: ModuleState = {
  list: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<Module[]>) => {
      state.list = action.payload
    },
    toggleActive: (state, action: PayloadAction<ToggleActivePayload>) => {
      const module = state.list.find(
        (item) => item.code === action.payload.code
      )
      if (module) {
        module.isActive = action.payload.isActive
      }
    },
  },
})

export const { showLoader, hideLoader, clearList, setList, toggleActive } =
  slice.actions

export default slice.reducer
