import { Icon } from '@liveconnect/icons'
import { ColumnDef } from '@tanstack/react-table'
import { t } from 'i18next'
import { FC, useMemo } from 'react'
import ContentTable from '../../../components/ContentTable'
import { TenantUrl } from '../../../core/tenants/types'
import useNotifications from '../../../utils/notifications/useNotifications'

interface TenantUrlTableProps {
  urls: TenantUrl[]
}

const TenantUrlTable: FC<TenantUrlTableProps> = ({ urls }) => {
  const notify = useNotifications()
  const handleCopyUrl = (url: string) => {
    navigator.clipboard.writeText(url)
    notify.success(t('tenant.detail.table.copy.success'))
  }

  const renderUrlCell = (row: TenantUrl) => {
    return (
      <div className="d-flex justify-content-between">
        <div>{row.url}</div>
        <div onClick={() => handleCopyUrl(row.url)}>
          <Icon name="content_copy" />
        </div>
      </div>
    )
  }
  const columns: ColumnDef<TenantUrl>[] = useMemo(
    () => [
      {
        accessorKey: 'code',
        header: t('tenant.detail.table.header.module'),
      },
      {
        accessorKey: 'url',
        header: t('tenant.detail.table.header.url'),
        cell: ({ row }) => renderUrlCell(row.original),
      },
    ],
    [renderUrlCell, t]
  )

  return (
    <div className="mt-5 mb-5">
      <ContentTable
        title={t('tenant.detail.table.title')}
        columns={columns}
        data={urls}
      />
    </div>
  )
}

export default TenantUrlTable
