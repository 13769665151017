import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormDate,
  FormProvider,
  FormTextInput,
  Loader,
} from '@liveconnect/components'
import FormActions from '../../components/FormActions'
import { Main } from '../../components/Main'
import { buildValidationSchema } from './validations'
import { TenantDetail } from '../../core/tenants/types'
import { useEffect } from 'react'
import useTenants from '../../core/tenants/useTenants'
import { useParams } from 'react-router'
import useNotifications from '../../utils/notifications/useNotifications'
import TenantUrlTable from './UrlTable'

const TenantDetailPage = () => {
  const { tenantId } = useParams()
  const { t } = useTranslation()
  const notify = useNotifications()
  const { detail, fetchDetail, updateDetail } = useTenants()
  const methods = useForm<TenantDetail>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = methods

  const initForm = () => {
    detail && reset(detail)
  }

  const handleCancel = () => {
    initForm()
  }

  const onSubmit = (data: TenantDetail) => {
    try {
      tenantId && updateDetail(tenantId, { name: data.name })
      notify.success(t('tenant.detail.submit.success'))
    } catch (e) {
      notify.error(t('tenant.detail.submit.error'))
    }
  }

  useEffect(() => {
    initForm()
  }, [detail])

  useEffect(() => {
    tenantId && fetchDetail(tenantId)
  }, [tenantId])

  return (
    <Main ariaLabelledby="identity-title" className="TenantDetail">
      <div className="sticky-container">
        <div className="d-flex justify-content-between mb-5">
          <div>
            <h1 id="identity-title" className="h3">
              {t('tenant.detail.title')}
            </h1>
            <p>{t('tenant.detail.hint')}</p>
          </div>
        </div>
        <FormProvider methods={methods}>
          <div className="row">
            <div className="h4 mb-4">{t('tenant.detail.data')}</div>
          </div>
          <div className="row">
            <div className="col-9">
              <FormTextInput
                control={control}
                name="name"
                label={t('tenant.detail.name.label')}
                placeholder={t('tenant.detail.name.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-9">
              <FormTextInput
                control={control}
                name="slug"
                label={t('tenant.detail.slug.label')}
                type="text"
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <FormDate
                control={control}
                name="createdAt"
                label={t('tenant.detail.date.label')}
                disabled={true}
              />
            </div>
          </div>
        </FormProvider>
        <TenantUrlTable urls={detail?.urls || []} />
      </div>
      <FormActions>
        <div>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-outline-primary mr-2"
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={isSubmitting || !isValid || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? <Loader /> : t('common.save')}
          </button>
        </div>
      </FormActions>
    </Main>
  )
}

export default TenantDetailPage
