import { Pagination, SearchInput } from '@liveconnect/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Main } from '../../components/Main'
import Restricted from '../../containers/Restricted'
import useTenants from '../../core/tenants/useTenants'
import CreateTenant from './CreateTenant'
import TenantListItem from './ListItem'

import './styles.scss'

const TenantsPage = () => {
  const { t } = useTranslation()
  const { list, availablePages, page, availableItems, pageSize, fetchList } =
    useTenants()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  useEffect(() => {
    fetchList({ page: 1 })
  }, [])

  return (
    <>
      <Main ariaLabelledby="list-title" className="TenantList">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h1 id="list-title" className="h3">
              {t('tenant.list.title')}
            </h1>
            <p>{t('tenant.list.hint')}</p>
          </div>
          <Restricted to={['global-admin']}>
            <button
              className="btn btn-primary h-100"
              onClick={() => setShowCreateModal(true)}
            >
              {t('tenant.list.add')}
            </button>
          </Restricted>
        </div>
        <SearchInput
          label="search"
          onSearch={(s: string) => {
            fetchList({ page: 1, search: s })
          }}
          placeholder={t('common.search')}
        />
        {list.map((tenant) => (
          <TenantListItem tenant={tenant} key={tenant.id} />
        ))}
        <Pagination
          currentPage={page - 1}
          totalPages={availablePages}
          totalItems={availableItems}
          pageSize={pageSize}
          singlePageText={(count) => t('common.pager.singlePage', { count })}
          multiplePageText={(start, end, total) =>
            t('common.pager.multiplePage', { start, end, total })
          }
          onChange={(pageIndex) => fetchList({ page: pageIndex + 1 })}
        />
      </Main>
      {showCreateModal && (
        <CreateTenant onClose={() => setShowCreateModal(false)} />
      )}
    </>
  )
}

export default TenantsPage
