import { stringify } from 'query-string'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../core/auth/useAuth'

import useSettings from '../../core/settings/useSettings'
import useUi from '../../core/ui/useUi'
import {
  DeleteParams,
  FetchError,
  GetParams,
  PatchParams,
  PostParams,
  PutParams,
  UseFetchResult,
} from './types'

const useFetch = (): UseFetchResult => {
  const navigate = useNavigate()
  const { login } = useAuth()
  const { settings } = useSettings()
  const { showError } = useUi()

  const baseUrl = settings?.bff.url

  const getHeaders = (
    isPublic?: boolean,
    isMultipart?: boolean
  ): HeadersInit => {
    const requestHeaders: HeadersInit = new Headers()
    if (!isMultipart) {
      requestHeaders.set('Content-Type', 'application/json')
      requestHeaders.set('Accept', 'application/json')
    }
    if (!isPublic) {
      requestHeaders.set(
        'Authorization',
        `Bearer ${localStorage.ifema_saas_access_token}`
      )
    }
    return requestHeaders
  }

  const errorHandler = async (response: Response) => {
    if (response.status === 403) {
      navigate('/access-denied')
    }
    if (response.status === 401) {
      login()
    }
    if (response.status === 500) {
      showError('unknown')
    }
    const error = await response.clone().json()
    throw {
      errorType: 'controlled',
      status: response.status,
      body: error,
    }
  }

  const responseHandler = async <T,>(
    response: Response
  ): Promise<T | undefined> => {
    if (!response.ok) {
      await errorHandler(response)
    }
    if (response.status === 204) return
    if (response.json) {
      const result: T = await response.json()
      return result
    }
    return
  }

  const get = async <K, T>({
    endpoint,
    params,
    isPublic,
    signal,
  }: GetParams<K>): Promise<T | undefined> => {
    try {
      const url = `${baseUrl}/${endpoint}${
        params ? `?${stringify(params)}` : ''
      }`
      const response = await fetch(url, {
        method: 'GET',
        headers: getHeaders(isPublic),
        signal,
      })
      const parsedResponse = await responseHandler<T>(response)
      return parsedResponse
    } catch (e) {
      const error = e as FetchError
      if (error.name === 'AbortError') throw error
      if (error.errorType !== 'controlled') {
        showError('unknown')
      } else {
        throw e
      }
    }
  }

  const post = async <T,>({
    endpoint,
    body,
    isPublic,
    isMultipart,
  }: PostParams): Promise<T | undefined> => {
    try {
      const url = `${baseUrl}/${endpoint}`
      const response = await fetch(url, {
        body: (isMultipart ? body : JSON.stringify(body)) as BodyInit,
        method: 'POST',
        headers: getHeaders(isPublic, isMultipart),
      })
      const parsedResponse = await responseHandler<T>(response)
      return parsedResponse
    } catch (e) {
      if ((e as FetchError).errorType !== 'controlled') {
        showError('unknown')
      }
      throw e
    }
  }

  const put = async <T,>({
    endpoint,
    id,
    body,
    isPublic,
    isMultipart,
  }: PutParams): Promise<T | undefined> => {
    try {
      let url = `${baseUrl}/${endpoint}`
      if (typeof id !== 'undefined') url += `/${id}`
      const response = await fetch(url, {
        body: (isMultipart ? body : JSON.stringify(body)) as BodyInit,
        method: 'PUT',
        headers: getHeaders(isPublic, isMultipart),
      })
      const parsedResponse = await responseHandler<T>(response)
      return parsedResponse
    } catch (e) {
      if ((e as FetchError).errorType !== 'controlled') {
        showError('unknown')
      } else {
        throw e
      }
    }
  }

  const patch = async <T,>({
    endpoint,
    id,
    body,
    isPublic,
    isMultipart,
  }: PatchParams): Promise<T | undefined> => {
    try {
      const url = `${baseUrl}/${endpoint}/${id}`
      const response = await fetch(url, {
        body: (isMultipart ? body : JSON.stringify(body)) as BodyInit,
        method: 'PATCH',
        headers: getHeaders(isPublic, isMultipart),
      })
      const parsedResponse = await responseHandler<T>(response)
      return parsedResponse
    } catch (e) {
      if ((e as FetchError).errorType !== 'controlled') {
        showError('unknown')
      } else {
        throw e
      }
    }
  }

  const del = async <T,>({
    endpoint,
    id,
    isPublic,
  }: DeleteParams): Promise<T | undefined> => {
    try {
      const url = `${baseUrl}/${endpoint}/${id}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: getHeaders(isPublic),
      })
      const parsedResponse = await responseHandler<T>(response)
      return parsedResponse
    } catch (e) {
      if ((e as FetchError).errorType !== 'controlled') {
        showError('unknown')
      } else {
        throw e
      }
    }
  }

  return {
    get,
    post,
    put,
    patch,
    del,
  }
}

export default useFetch
