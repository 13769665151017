import { FC } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import SigninCallback from '../pages/auth/SigninCallback'
import Logout from '../pages/auth/Logout'
import SigninSilentRenew from '../pages/auth/SigninSilentRenew'
import NotFound from '../pages/error/NotFound'
import AccessDenied from '../pages/error/AccessDenied'
import SaasRouter from './SaasRouter'
import { ErrorBoundary } from '../components/errors/ErrorBoundary'

const AppRouter: FC = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/signin-callback" element={<SigninCallback />} />
          <Route path="/signin-silent-renew" element={<SigninSilentRenew />} />
          <Route path="*" element={<SaasRouter />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default AppRouter
