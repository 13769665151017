export enum IdsConfigType {
  LinkedIn = 'LinkedIn',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Google = 'Google',
  AzureActiveDirectory = 'AzureActiveDirectory',
}

export interface IdsConfig {
  idsLogin: boolean
  externalProviders: IdsConfigProvider[]
}

export interface IdsConfigProvider {
  type: IdsConfigType
  isEnabled: boolean
  clientId: string
  clientSecret: string
  authority?: string
  scopes?: string
}

export interface IdsConfigState {
  detail: IdsConfig | null
}

export interface UseIdsConfigResult {
  idsConfig: IdsConfig | null
  fetchIdsConfig: () => void
  updateIdsConfig: (body: IdsConfig) => void
  clearIdsConfig: () => void
}
