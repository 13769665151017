import { useParams } from 'react-router'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { IdsConfig, UseIdsConfigResult } from './types'
import useFetch from '../../utils/fetch/useFetch'
import { clearDetail, setDetail } from './store'

const useIdsConfig = (): UseIdsConfigResult => {
  const dispatch = useAppDispatch()
  const { tenantId } = useParams()

  const { detail } = useAppSelector((state) => state.idsConfig)
  const { get, put } = useFetch()

  const endpoint = `tenants/${tenantId}/ids-configuration`

  const fetchDetail = async () => {
    const response: IdsConfig | undefined = await get({
      endpoint,
    })
    response && dispatch(setDetail(response))
  }

  const updateDetail = async (body: IdsConfig) => {
    const response: IdsConfig | undefined = await put({
      endpoint,
      body,
    })
    const newDetail = JSON.parse(JSON.stringify(body))
    response && dispatch(setDetail(newDetail))
  }

  return {
    idsConfig: detail,
    fetchIdsConfig: fetchDetail,
    updateIdsConfig: updateDetail,
    clearIdsConfig: () => dispatch(clearDetail()),
  }
}

export default useIdsConfig
