import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseSaasAdminsResult,
  SaasAdminRequestParams,
  SaasAdminListResponse,
  SaasAdminCreateForm,
} from './types'
import { setList, clearList, showLoader, hideLoader, setDetail } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useSaasAdmins = (): UseSaasAdminsResult => {
  const dispatch = useAppDispatch()
  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
    detail,
  } = useAppSelector((state) => state.saasAdmins)
  const { get, del, post, patch } = useFetch()

  const endpoint = 'saas-admins'

  const parseParams = (requestParams?: SaasAdminRequestParams) => {
    const params: SaasAdminRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getList = async (params?: SaasAdminRequestParams) => {
    const response: SaasAdminListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchList = async (params?: SaasAdminRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseParams(params)
    const response: SaasAdminListResponse | undefined = await getList(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const fetchDetail = async (id: string) => {
    dispatch(showLoader())
    const response: SaasAdminCreateForm | undefined = await get({
      endpoint: `${endpoint}/${id}`,
    })
    response && dispatch(setDetail(response))
    dispatch(hideLoader())
  }

  const deleteAdmin = async (id: string) => {
    await del({ endpoint, id })
  }

  const createAdmin = async (body: SaasAdminCreateForm) => {
    await post({ endpoint, body })
  }

  const updateAdmin = async (id: string, body: SaasAdminCreateForm) => {
    await patch({ endpoint, id, body })
  }

  return {
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    detail,
    fetchList,
    clearList: () => dispatch(clearList()),
    fetchDetail,
    deleteAdmin,
    createAdmin,
    updateAdmin,
  }
}

export default useSaasAdmins
