import { useEffect, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router'
import Layout from '../containers/Layout'

import useAuth from '../core/auth/useAuth'
import useMe from '../core/me/useMe'
import usePermissions from '../core/permissions/usePermissions'
import { DEFAULT_LANG } from '../i18n/config'

const PrivateRoute: FC = () => {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { login, loaded, isLoggedIn, user } = useAuth()
  const { isLoaded: hasPermissionsLoaded, fetchList: fetchPermissions } =
    usePermissions()
  const { me, fetchMe } = useMe()

  const initPermissions = async () => {
    try {
      await fetchPermissions()
    } catch (e) {
      navigate('/access-denied')
    }
  }

  useEffect(() => {
    const lang = me?.isoCode ?? DEFAULT_LANG
    i18n.changeLanguage(lang)
  }, [me?.isoCode])

  useEffect(() => {
    if (!isLoggedIn) {
      login()
    }
    if (user && !hasPermissionsLoaded) {
      initPermissions()
      fetchMe()
    }
  }, [loaded, isLoggedIn, login, hasPermissionsLoaded, user])

  return isLoggedIn && hasPermissionsLoaded ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : null
}

export default PrivateRoute
