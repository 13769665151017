import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorPage } from '@liveconnect/events-ui'

const NotFound: FC = () => {
  const { t } = useTranslation()

  return (
    <ErrorPage className="not-found">
      <>
        <ErrorPage.Icon name="report_problem" />

        <ErrorPage.Title text={t('pageError.notFound.title')} />
        <ErrorPage.Body text={t('pageError.notFound.body')} />
      </>
    </ErrorPage>
  )
}

export default NotFound
