import { matchPath, useLocation } from 'react-router-dom'

type CommunitiesParams = {
  tenantId: string
  basePath: string
}

export const tenantsPathPattern = '/t/:tenantId'

export function useCustomRouter(): CommunitiesParams {
  const { pathname } = useLocation()
  const match = matchPath({ path: tenantsPathPattern }, pathname) ??
    matchPath({ path: `${tenantsPathPattern}`, end: false }, pathname) ?? {
      params: { tenantId: '', basePath: '' },
    }

  const { params } = match
  const tenantId = params.tenantId || ''
  const basePath = ''

  return {
    tenantId,
    basePath,
  }
}
