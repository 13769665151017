import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  TenantDetail,
  TenantListPayload,
  TenantState,
  ToggleEnablePayload,
} from './types'

const initialState: TenantState = {
  detail: null,
  list: [],
  page: 1,
  pageSize: 20,
  availablePages: 1,
  availableItems: 0,
  search: '',
  isLoaded: false,
}

export const slice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<TenantListPayload>) => {
      state.list = action.payload.list
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.availableItems = action.payload.availableItems
      state.search = action.payload.search
    },
    toggleEnable: (state, action: PayloadAction<ToggleEnablePayload>) => {
      const tenant = state.list.find((item) => item.id === action.payload.id)
      if (tenant) {
        tenant.enabled = action.payload.enabled
      }
    },
    setDetail: (state, action: PayloadAction<TenantDetail>) => {
      state.detail = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  clearList,
  setList,
  toggleEnable,
  setDetail,
} = slice.actions

export default slice.reducer
