import { useState } from 'react'
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  Row,
  ColumnDef,
  Cell,
  Header,
  HeaderGroup,
} from '@tanstack/react-table'
import classnames from 'classnames'

import { Icon, IconName } from '@liveconnect/icons'
import { ContextMenu } from '@liveconnect/communities-ui'
import { FilterButton, Loader, SearchInput } from '@liveconnect/components'
import EmptyState from '../EmptyState'

import './styles.scss'

export interface TableRowAction {
  onClick: () => void
  label: string
  icon: string
  isHidden?: boolean
}

interface ReactTableProps<T> {
  title: string
  isLoaded?: boolean
  emptyText?: string
  data: T[]
  columns: ColumnDef<T>[]
  isExpandable?: boolean
  searchPlaceholder?: string
  filterLabel?: string
  isFilterActive?: boolean
  onFilterClick?: () => void
  onSearch?: (search: string) => void
  actions?: (row: T) => TableRowAction[]
}

type WithChildren<T> = T & { children?: T[] }

type ReactTableWithChildrenProps<T> = ReactTableProps<WithChildren<T>>

const ContentTable = <T,>({
  title,
  isLoaded = true,
  emptyText,
  data,
  columns,
  isExpandable,
  searchPlaceholder,
  isFilterActive,
  filterLabel,
  onFilterClick,
  onSearch,
  actions,
}: ReactTableWithChildrenProps<T>) => {
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const table = useReactTable<T>({
    columns,
    data,
    state: {
      expanded,
    },
    getSubRows: (row: WithChildren<T>) => row.children ?? [],
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  })

  const renderActions = (row: T) => {
    if (!actions) return
    const rowActions = actions(row)
    const visibleActions = rowActions.filter((item) => !item.isHidden)
    if (rowActions.length > 2) {
      return (
        <div className="custom-table-body__actions custom-table-body__actions--dropdown">
          <ContextMenu items={visibleActions} />
        </div>
      )
    }
    return (
      <div className="custom-table-body__actions custom-table-body__actions--inline">
        {visibleActions.map((item: TableRowAction, index: number) => (
          <button title={item.label} onClick={item.onClick} key={index}>
            <Icon name={item.icon as IconName} />
          </button>
        ))}
      </div>
    )
  }

  return (
    <div className="custom-table">
      <div className="custom-table-header">
        <div className="d-flex align-items-center w-100">
          <div className="custom-table-header__title col-6">{title}</div>
          <div className="custom-table-header__filters col-6">
            {onSearch && (
              <SearchInput
                label="search"
                onSearch={onSearch}
                placeholder={searchPlaceholder}
              />
            )}
            {onFilterClick && (
              <FilterButton
                onClick={onFilterClick}
                active={isFilterActive as boolean}
                label={filterLabel as string}
              />
            )}
          </div>
        </div>
      </div>
      {!isLoaded ? (
        <Loader />
      ) : data.length === 0 ? (
        <EmptyState text={emptyText} />
      ) : (
        <table className="table table-bordered">
          <thead className="custom-table-head">
            {table
              .getHeaderGroups()
              .map((headerGroup: HeaderGroup<T>, i: number) => (
                <tr key={`${i}headerTr`}>
                  {isExpandable && (
                    <th className="custom-table-head__expandable-header">
                      {''}
                    </th>
                  )}
                  {headerGroup.headers.map((header: Header<T, unknown>) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width:
                            header.getSize() !== 150
                              ? header.getSize()
                              : undefined,
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    )
                  })}
                  {actions && <th>{''}</th>}
                </tr>
              ))}
          </thead>
          <tbody className="custom-table-body">
            {table.getRowModel().rows.map((row: Row<T>, i: number) => (
              <tr
                key={`${i}bodyTr`}
                className={classnames({
                  'custom-table-body__subrow': row.depth > 0,
                })}
              >
                {isExpandable && (
                  <td className="custom-table-body__expandable-col">
                    {row.getCanExpand() && (
                      <div
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                      >
                        {row.getIsExpanded() ? (
                          <Icon name="keyboard_arrow_up" />
                        ) : (
                          <Icon name="keyboard_arrow_down" />
                        )}
                      </div>
                    )}
                  </td>
                )}
                {row.getVisibleCells().map((cell: Cell<T, T>, i: number) => {
                  return (
                    <td key={`${i}bodyTd`}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
                {actions && <td>{renderActions(row.original)}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default ContentTable
