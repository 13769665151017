import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    fromEmail: yup
      .string()
      .label(t('license.email.form.from.label'))
      .email(t('validations.email'))
      .required(({ label }) => t('validations.required', { label })),
    fromDisplayName: yup
      .string()
      .label(t('license.email.form.display.label'))
      .required(({ label }) => t('validations.required', { label })),
    providerEmailApiKey: yup
      .string()
      .label(t('license.email.form.api.label'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
