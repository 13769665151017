import { useParams } from 'react-router'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseAdminsResult,
  AdminsRequestParams,
  AdminListResponse,
  CreateAdminType,
  FindAdminResponse,
  Profile,
} from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'
import useError from '../../utils/errors/useError'

const useAdmins = (): UseAdminsResult => {
  const dispatch = useAppDispatch()
  const { tenantId } = useParams()

  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.admins)
  const { get, post, del, patch } = useFetch()
  const { notifyApiError } = useError()

  const errorModel = 'admin'
  const endpoint = `tenants/${tenantId}/admins`


  const parseParams = (requestParams?: AdminsRequestParams) => {
    const params: AdminsRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getList = async (params?: AdminsRequestParams) => {
    const response: AdminListResponse | undefined = await get({
      endpoint: endpoint,
      params,
    })
    return response
  }

  const fetchList = async (params?: AdminsRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseParams(params)
    const response: AdminListResponse | undefined = await getList(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const createAdmin = async (user: CreateAdminType) => {
    try {
      await post({ endpoint: endpoint, body: user })
    } catch (e) {
      notifyApiError(e, errorModel, 'create')
    }
  }

  const updateAdmin = async (id: string, profiles: string[]) => {
    try {
      await patch({ endpoint: endpoint, id, body: { profiles } })
    } catch (e) {
      notifyApiError(e, errorModel, 'edit')
    }
  }

  const deleteAdmin = async (id: string) => {
    try {
      await del({ endpoint: endpoint, id })
    } catch (e) {
      notifyApiError(e, errorModel, 'delete')
    }
  }

  const findAdminByEmail = async (
    email: string
  ): Promise<FindAdminResponse | undefined> => {
    return await get({
      endpoint: `${endpoint}/find-by-email`,
      params: { email },
    })
  }

  const getProfiles = async () => {
    const response: Profile[] | undefined = await get({
      endpoint: `${endpoint}/profiles`,
    })
    return response
  }

  return {
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    createAdmin,
    updateAdmin,
    deleteAdmin,
    findAdminByEmail,
    getProfiles,
  }
}

export default useAdmins
