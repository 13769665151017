import { useEffect, FC } from 'react'

import useAuth from '../../../core/auth/useAuth'

const Logout: FC = () => {
  const { logout } = useAuth()
  useEffect(() => {
    logout()
  }, [logout])
  return null
}

export default Logout
