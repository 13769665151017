import { FC, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Header, LogoImage } from '@liveconnect/components'

import useAuth from '../../core/auth/useAuth'
import useUi from '../../core/ui/useUi'
import { ApplicationLogo } from './ApplicationLogo'
import logo from '../../../assets/images/logo.svg'

import './styles.scss'

const AppHeader: FC = () => {
  const { pathname } = useLocation()

  const { t } = useTranslation()
  const { user } = useAuth()
  const { toggleShowPanel, isPanelVisible } = useUi()

  useEffect(() => {
    isPanelVisible && toggleShowPanel()
  }, [pathname])

  return (
    <Header className="header">
      <Header.Left>
        <Header.Logo>
          <Link to="/" className="navbar-brand">
            <ApplicationLogo />
          </Link>
        </Header.Logo>
      </Header.Left>

      <Header.Right>
        <Header.Logo>
          <LogoImage
            src={logo}
            size="sm"
            alt="ifemaLogo"
            className="header__tenant-logo"
          />
        </Header.Logo>

        <Header.Menu>
          <Header.AvatarButton
            onClick={toggleShowPanel}
            active={isPanelVisible}
            avatar={{
              name: user?.profile.name ?? '',
              surname: user?.profile.family_name ?? '',
              imageUrl: user?.profile.picture ?? '',
              alt: t('header.avatar'),
            }}
          />
        </Header.Menu>
      </Header.Right>
    </Header>
  )
}

export default AppHeader
