import { configureStore } from '@reduxjs/toolkit'

import auth from '../auth/store'
import ui from '../ui/store'
import settings from '../settings/store'
import permissions from '../permissions/store'
import tenants from '../tenants/store'
import saasAdmins from '../saasAdmins/store'
import me from '../me/store'
import modules from '../modules/store'
import tenantUrls from '../tenantUrls/store'
import licenses from '../licenses/store'
import idsConfig from '../idsConfig/store'
import admins from '../admins/store'

export const store = configureStore({
  reducer: {
    auth,
    ui,
    settings,
    permissions,
    tenants,
    saasAdmins,
    me,
    modules,
    tenantUrls,
    licenses,
    idsConfig,
    admins,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export * from './hooks'
