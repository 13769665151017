import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    externalProviders: yup.array().of(
      yup.object({
        type: yup.string(),
        isEnabled: yup.boolean(),
        clientId: yup.string().when('isEnabled', {
          is: (value: boolean) => value,
          then: yup
            .string()
            .trim()
            .required(({ label }) => t('validations.required', { label })),
        }),
        clientSecret: yup.string().when('isEnabled', {
          is: (value: boolean) => value,
          then: yup
            .string()
            .trim()
            .required(({ label }) => t('validations.required', { label })),
        }),
        authority: yup.string().when(['isEnabled', 'type'], {
          is: (isEnabled: boolean, type: string) =>
            isEnabled && type === 'AzureActiveDirectory',
          then: yup
            .string()
            .trim()
            .url(t('validations.url'))
            .required(({ label }) => t('validations.required', { label })),
        }),
        scopes: yup.string().when(['isEnabled', 'type'], {
          is: (isEnabled: boolean, type: string) =>
            isEnabled && type === 'AzureActiveDirectory',
          then: yup
            .string()
            .trim()
            .required(({ label }) => t('validations.required', { label })),
        }),
      })
    ),
  })
}
