import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { useSettingsResult, Settings } from './types'
import { setSettings } from './store'
import { useTheme } from '@liveconnect/components'

const useSettings = (): useSettingsResult => {
  const dispatch = useAppDispatch()
  const { settings } = useAppSelector((state) => state.settings)
  const { changeThemeVars } = useTheme()

  const fetchSettings = async () => {
    const settingsResponse = await fetch('/api/v1/settings')
    const appSettings: Settings = await settingsResponse.json()
    dispatch(setSettings(appSettings))
    // TODO añadir colores del saas al settings o BORRAR
    changeThemeVars({
      //link: 'red',
    })
  }

  return {
    settings,
    fetchSettings,
  }
}

export default useSettings
