import { useEffect, useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { ToggleControl } from '@liveconnect/components'
import { useTranslation } from 'react-i18next'
import ContentTable from '../../components/ContentTable'
import { Main } from '../../components/Main'
import useModules from '../../core/modules/useModules'
import useUi from '../../core/ui/useUi'
import useNotifications from '../../utils/notifications/useNotifications'
import { Module } from '../../core/modules/types'

const Modules = () => {
  const { t } = useTranslation()
  const notifiy = useNotifications()
  const { showConfirmation } = useUi()
  const { list, isLoaded, fetchList, toggleActivate } = useModules()

  const handleConfirmToggleActivate = (code: string, isActive: boolean) => {
    showConfirmation({
      title: t(
        `modules.list.item.${
          isActive ? 'deactivate' : 'activate'
        }.confirm.title`
      ),
      subtitle: t(
        `modules.list.item.${
          isActive ? 'deactivate' : 'activate'
        }.confirm.subtitle`
      ),
      text: t(
        `modules.list.item.${isActive ? 'deactivate' : 'activate'}.confirm.text`
      ),
      confirmText: t(`common.${isActive ? 'deactivate' : 'activate'}`),
      iconName: 'report_problem',
      onConfirm: () => handleToggleActivate(code, isActive),
      cancelText: t('common.cancel'),
    })
  }

  const handleToggleActivate = async (code: string, isActive: boolean) => {
    try {
      await toggleActivate(code, !isActive)
      notifiy.success(
        t(`modules.list.${isActive ? 'deactivate' : 'activate'}.success`)
      )
    } catch (e) {
      notifiy.error(
        t(`modules.list.${isActive ? 'deactivate' : 'activate'}.error`)
      )
    }
  }

  const renderToggleCell = (row: Module) => {
    return (
      <ToggleControl
        value={row.isActive}
        id={row.code}
        name={row.code}
        onChange={(value) => handleConfirmToggleActivate(row.code, !value)}
        label=""
      />
    )
  }

  const renderCodeCell = (code: string) => t(`modules.list.table.code.${code}`)

  const columns: ColumnDef<Module>[] = useMemo(
    () => [
      {
        accessorKey: 'code',
        header: t('modules.list.table.code'),
        cell: ({ row }) => renderCodeCell(row.original.code),
      },
      {
        accessorKey: 'isActive',
        maxSize: 25,
        header: t('modules.list.table.active'),
        cell: ({ row }) => renderToggleCell(row.original),
      },
    ],
    []
  )

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="Modules">
      <div className="d-flex justify-content-between">
        <div>
          <h1 id="user-list-title" className="h3">
            {t('modules.list.title')}
          </h1>
          <p>{t('modules.list.hint')}</p>
        </div>
      </div>
      <section className="mt-3">
        <div className="Modules__list">
          <ContentTable
            columns={columns}
            data={list}
            title={t('modules.list.table.title')}
            isLoaded={isLoaded}
          />
        </div>
      </section>
    </Main>
  )
}

export default Modules
