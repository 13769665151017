import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Icon } from '@liveconnect/icons'
import { FormProvider, FormTextInput, Loader } from '@liveconnect/components'
import { Main } from '../../components/Main'
import useTenantUrls from '../../core/tenantUrls/useTenantUrls'
import useUi from '../../core/ui/useUi'
import useNotifications from '../../utils/notifications/useNotifications'
import { buildValidationSchema } from './validations'
import FormActions from '../../components/FormActions'
import { TenantUrlUpdateItem } from '../../core/tenantUrls/types'

import './styles.scss'

const UrlPage = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { list, fetchList, updateUrls } = useTenantUrls()

  const methods = useForm<Record<string, string>>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t, list)),
  })
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, isDirty, isSubmitting },
  } = methods

  const handleReset = () => {
    const values = createItemsArray()
    reset(values)
  }

  const parentGroupingArray = useMemo(
    () => [...new Set(list.map((item) => item.parentGrouping))],
    [list]
  )

  const createGroupingArray = (parentGroupName: string) => {
    const parentGroup = list.filter(
      (item) => item.parentGrouping === parentGroupName
    )
    const uniqueGrouping = [
      ...new Set(parentGroup.map((item) => item.grouping)),
    ]
    return uniqueGrouping.map((name: string) => ({
      name,
      items: parentGroup.filter((item) => item.grouping === name),
    }))
  }

  const createItemsArray = () => {
    const obj: Record<string, string> = {}
    parentGroupingArray.map((parentGroup: string) =>
      createGroupingArray(parentGroup).map((group) =>
        group.items.map((item) => {
          obj[item.code] = item.url
        })
      )
    )
    return obj
  }

  const handleCopy = (inputName: string) => {
    navigator.clipboard.writeText(getValues(inputName))
    notify.success(t('tenanturls.copy.success'))
  }

  const parseSubmitData = (data: Record<string, string>) => {
    const arr = []
    for (const key in data) {
      arr.push({ code: key, url: data[key] })
    }
    return arr
  }

  const onSubmit = async (data: Record<string, string>) => {
    try {
      const parsedData: TenantUrlUpdateItem[] = parseSubmitData(data)
      await updateUrls(parsedData)
      await fetchList()
      notify.success(t('tenanturls.create.success'))
    } catch (error: unknown) {
      notify.error(t('tenanturls.create.error'))
    }
  }

  const onCancel = () => {
    handleReset()
  }

  useEffect(() => {
    handleReset()
  }, [list])

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="TenantUrls">
      <div className="d-flex justify-content-between">
        <div>
          <h1 id="user-list-title" className="h3">
            {t('tenanturls.list.title')}
          </h1>
          <p>{t('tenanturls.list.hint')}</p>
          <h2 id="user-list-title" className="h4 mt-5">
            {t('tenanturls.list.subtitle')}
          </h2>
          <p>{t('tenanturls.list.hint2')}</p>
        </div>
      </div>
      <FormProvider methods={methods}>
        <section className="mt-3">
          <div className="TenantUrls__list">
            {parentGroupingArray.map((parentGroup: string) => (
              <div className="TenantUrls__parent-group" key={parentGroup}>
                <div className="TenantUrls__parent-group__name">
                  {t(`tenanturls.list.group.${parentGroup}`)}
                </div>
                {createGroupingArray(parentGroup).map((group) => (
                  <div className="TenantUrls__group" key={group.name}>
                    <div className="TenantUrls__group__name">
                      {t(`tenanturls.list.group.${group.name}`)}
                    </div>
                    <div className="TenantUrls__group__list">
                      {group.items.map((item) => (
                        <div
                          key={item.code}
                          className="TenantUrls__group__item"
                        >
                          <FormTextInput
                            control={control}
                            name={item.code}
                            label={t(`tenanturls.list.${item.code}`)}
                            placeholder={t('saasAdmins.form.email.placeholder')}
                            type="text"
                            required={true}
                            suffix={
                              <button
                                className="btn lc-btn-icon"
                                onClick={() => handleCopy(item.code)}
                              >
                                <Icon name="content_copy" />
                              </button>
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </section>
      </FormProvider>
      <FormActions>
        <button className="btn btn-outline-primary" onClick={onCancel}>
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          disabled={!isValid || isSubmitting || !isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? <Loader /> : t('common.save')}
        </button>
      </FormActions>
    </Main>
  )
}

export default UrlPage
