import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormProvider,
  FormTextInput,
  FormToggle,
  Loader,
} from '@liveconnect/components'
import FormActions from '../../components/FormActions'
import { Main } from '../../components/Main'
import useNotifications from '../../utils/notifications/useNotifications'
import {
  IdsConfig,
  IdsConfigProvider,
  IdsConfigType,
} from '../../core/idsConfig/types'
import useIdsConfig from '../../core/idsConfig/useIdsConfig'
import { buildValidationSchema } from './validations'

const IdsConfigPage = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { idsConfig, fetchIdsConfig, updateIdsConfig } = useIdsConfig()
  const methods = useForm<IdsConfig>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      idsLogin: true,
      externalProviders: [],
    },
  })
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isDirty, isValid, isSubmitting },
  } = methods

  const { fields } = useFieldArray<IdsConfig>({
    control,
    name: 'externalProviders',
  })

  const watchFieldArray = watch('externalProviders')
  watch('idsLogin')

  fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })

  const isAtLeastOneEnabled = () => {
    const values = getValues()
    const providerEnabled = values.externalProviders.some(
      (item) => item.isEnabled
    )
    return providerEnabled || values.idsLogin
  }

  const initForm = () => {
    if (idsConfig) {
      const _idsConfig = { ...idsConfig }
      for (const type of Object.keys(IdsConfigType)) {
        const hasFormType = _idsConfig.externalProviders.find(
          (item) => item.type === type
        )
        if (!hasFormType)
          _idsConfig.externalProviders = [
            ..._idsConfig.externalProviders,
            {
              type: type as IdsConfigType,
              isEnabled: false,
              clientId: '',
              clientSecret: '',
            },
          ]
      }
      reset(_idsConfig)
    }
  }

  const handleCancel = () => {
    initForm()
  }

  const onSubmit = async (data: IdsConfig) => {
    try {
      await updateIdsConfig(data)
      notify.success(t('idsConfig.submit.success'))
    } catch (e) {
      notify.error(t('idsConfig.submit.error'))
    }
  }

  useEffect(() => {
    initForm()
  }, [idsConfig])

  useEffect(() => {
    fetchIdsConfig()
  }, [])

  const renderProvider = (type: IdsConfigType, index: number) => {
    switch (type) {
      case 'AzureActiveDirectory':
        return (
          <div className="IdsConfig__provider mb-5" key={type}>
            <div className="row">
              <div className="col-6">
                <div className="IdsConfig__provider__title h4">
                  {t(`idsConfig.${type}`)}
                </div>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <FormToggle
                  control={control}
                  name={`externalProviders.${index}.isEnabled`}
                  label={t('idsConfig.isEnabled')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <FormTextInput
                  control={control}
                  name={`externalProviders.${index}.clientId`}
                  label={t('idsConfig.clientId.label')}
                  placeholder={t('idsConfig.clientId.placeholder')}
                  type="text"
                  required={getValues(`externalProviders.${index}.isEnabled`)}
                  disabled={!getValues(`externalProviders.${index}.isEnabled`)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <FormTextInput
                  control={control}
                  name={`externalProviders.${index}.clientSecret`}
                  label={t('idsConfig.clientSecret.label')}
                  placeholder={t('idsConfig.clientSecret.placeholder')}
                  type="text"
                  required={getValues(`externalProviders.${index}.isEnabled`)}
                  disabled={!getValues(`externalProviders.${index}.isEnabled`)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <FormTextInput
                  control={control}
                  name={`externalProviders.${index}.authority`}
                  label={t('idsConfig.authority.label')}
                  placeholder={t('idsConfig.authority.placeholder')}
                  type="text"
                  required={getValues(`externalProviders.${index}.isEnabled`)}
                  disabled={!getValues(`externalProviders.${index}.isEnabled`)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <FormTextInput
                  control={control}
                  name={`externalProviders.${index}.scopes`}
                  label={t('idsConfig.scopes.label')}
                  placeholder={t('idsConfig.scopes.placeholder')}
                  type="text"
                  required={getValues(`externalProviders.${index}.isEnabled`)}
                  disabled={!getValues(`externalProviders.${index}.isEnabled`)}
                />
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className="IdsConfig__provider mb-5" key={type}>
            <div className="row">
              <div className="col-6">
                <div className="IdsConfig__provider__title h4">
                  {t(`idsConfig.${type}`)}
                </div>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <FormToggle
                  control={control}
                  name={`externalProviders.${index}.isEnabled`}
                  label={t('idsConfig.isEnabled')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <FormTextInput
                  control={control}
                  name={`externalProviders.${index}.clientId`}
                  label={t('idsConfig.clientId.label')}
                  placeholder={t('idsConfig.clientId.placeholder')}
                  type="text"
                  required={getValues(`externalProviders.${index}.isEnabled`)}
                  disabled={!getValues(`externalProviders.${index}.isEnabled`)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <FormTextInput
                  control={control}
                  name={`externalProviders.${index}.clientSecret`}
                  label={t('idsConfig.clientSecret.label')}
                  placeholder={t('idsConfig.clientSecret.placeholder')}
                  type="text"
                  required={getValues(`externalProviders.${index}.isEnabled`)}
                  disabled={!getValues(`externalProviders.${index}.isEnabled`)}
                />
              </div>
            </div>
          </div>
        )
    }
  }

  console.log(
    'isValid',
    isValid,
    'isDirty',
    isDirty,
    'isAtLeastOneEnabled',
    isAtLeastOneEnabled()
  )

  return (
    <Main ariaLabelledby="identity-title" className="IdsConfig">
      <div className="sticky-container">
        <div className="d-flex justify-content-between mb-5">
          <div>
            <h1 id="identity-title" className="h3">
              {t('idsConfig.title')}
            </h1>
            <p>{t('idsConfig.hint')}</p>
          </div>
        </div>
        <FormProvider methods={methods}>
          <div className="row mb-5">
            <div className="col-6">
              <div className="IdsConfig__provider__title h4">
                {t('idsConfig.idsLogin')}
              </div>
            </div>
            <div className="col-3 d-flex justify-content-end">
              <FormToggle
                control={control}
                name="idsLogin"
                label={t('idsConfig.isEnabled')}
              />
            </div>
          </div>
          {fields.map((item: IdsConfigProvider, index: number) =>
            renderProvider(item.type, index)
          )}
        </FormProvider>
      </div>
      <FormActions>
        <div>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-outline-primary mr-2"
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={
              isSubmitting || !isValid || !isDirty || !isAtLeastOneEnabled()
            }
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? <Loader /> : t('common.save')}
          </button>
        </div>
      </FormActions>
    </Main>
  )
}

export default IdsConfigPage
