import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SlidePanel from '../../components/SlidePanel'
import useUi from '../../core/ui/useUi'
import useAuth from '../../core/auth/useAuth'
import { useCustomRouter } from '../../utils/extractParams'

import './styles.scss'

const SidePanel: FC = () => {
  const { t } = useTranslation()
  const { isPanelVisible, toggleShowPanel } = useUi()
  const { user, getChangePasswordUrl } = useAuth()
  const { basePath } = useCustomRouter()

  const redirectChangePass = () => {
    const url: void | undefined = getChangePasswordUrl()
    if (url === undefined) throw new Error('Problem with settings.authority')
  }

  return (
    <SlidePanel opened={isPanelVisible} close={toggleShowPanel}>
      <ul className="SidePanel">
        {user?.profile.idp === 'local' && (
          <li aria-hidden="true" onClick={redirectChangePass}>
            <div className="SidePanel__item">
              <span className="SidePanel__text">
                {t('header.menu.change_password')}
              </span>
            </div>
          </li>
        )}
        {/* <li>
          <Link className="SidePanel__item" to={`${basePath}/localization`}>
            <span className="SidePanel__text">{t('common.language')}</span>
          </Link>
        </li> */}
        <li>
          <Link className="SidePanel__item" to="/logout">
            <span className="SidePanel__text">{t('header.menu.logout')}</span>
          </Link>
        </li>
      </ul>
    </SlidePanel>
  )
}

export default SidePanel
