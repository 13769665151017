import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnDef, Row } from '@tanstack/react-table'
import { Pagination } from '@liveconnect/components'
import { Main } from '../../components/Main'
import useAdmins from '../../core/admins/useAdmins'
import ProfilesModal from './ProfilesModal'
import useNotifications from '../../utils/notifications/useNotifications'
import useUi from '../../core/ui/useUi'
import { Admin, Profile } from '../../core/admins/types'
import CreateAdmin from './CreateAdmin'
import ContentTable from '../../components/ContentTable'

import './styles.scss'

const AdminsScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const {
    list: eventAdmins,
    isLoaded,
    search,
    page,
    availablePages,
    availableItems,
    pageSize,
    fetchList: fetchListAdmins,
    clearList: clearAdminsList,
    deleteAdmin,
  } = useAdmins()

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showProfiles, setShowProfiles] = useState<Profile[] | null>(null)
  const [edittingUser, setEdittingUser] = useState<Admin>()




  const handleDelete = async (id: string) => {
    try {
      await deleteAdmin(id)
      notify.success(t('eventAdminsModal.delete.successFeedback'))
      fetchListAdmins({ page, search })
    } catch (e) {}
  }

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('eventAdminsModal.delete.modal.title'),
      subtitle: t('eventAdminsModal.delete.modal.subtitle'),
      iconName: 'report_problem',
      confirmText: t('common.delete'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        handleDelete(id)
      },
    })
  }

  const actions = (row: Admin) => {
    return [
      {
        onClick: () => setEdittingUser(row),
        label: t('eventAdmins.list.table.action.edit'),
        icon: 'edit',
      },
      {
        onClick: () => handleConfirmDelete(row.id),
        label: t('eventAdmins.list.table.action.delete'),
        icon: 'delete_outline',
      },
    ]
  }

  const columns: ColumnDef<Admin>[] = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: t('eventAdmins.list.table.email'),
      },
      {
        accessorKey: 'name',
        header: t('eventAdmins.list.table.name'),
        cell: ({ row }: { row: Row<Admin> }) =>
          `${row.original.name} ${row.original.surname}`,
      },
      {
        accessorKey: 'profiles',
        header: t('eventAdmins.list.table.profiles'),
        cell: ({ row }: { row: Row<Admin> }) =>
          renderProfilesCell(row.original),
      },
    ],
    []
  )

  const renderProfilesCell = (row: Admin) => {
    if (!row.profiles?.length) return ''
    return row.profiles.length === 1 ? (
      row.profiles[0].name
    ) : (
      <div
        className="Admins__profiles"
        onClick={() => setShowProfiles(row.profiles)}
      >
        <div className="Admins__profiles__cell">{`${
          row.profiles.length
        } ${t('eventAdmins.list.table.profiles')}`}</div>
      </div>
    )
  }

  const handleCloseForm = () => {
    setShowCreateModal(false)
    setEdittingUser(undefined)
  }

  useEffect(() => {
    fetchListAdmins()
    return () => {
      clearAdminsList()
    }
  }, [])

  return (
    <>
      <Main ariaLabelledby="profile-list-title" className="Admins">
        <div className="d-flex justify-content-between">
          <div>
            <h1 id="user-list-title" className="h3">
              {t('eventAdmins.title')}
            </h1>
            <p>{t('eventAdmins.hint')}</p>
          </div>
          <button
            className="btn btn-primary h-100"
            onClick={() => setShowCreateModal(true)}
          >
            {t('eventAdmins.add')}
          </button>
        </div>
            <section className="mt-3">
              <div className="Admins__list">
                <ContentTable
                  title={t('admin.table.title')}
                  columns={columns}
                  data={eventAdmins}
                  emptyText={t('eventAdmins.emptyList')}
                  actions={actions}
                  onSearch={(s: string) => {
                    fetchListAdmins({ page: 1, search: s })
                  }}
                  isLoaded={isLoaded}
                  searchPlaceholder={t('eventAdmins.search')}
                />
                <Pagination
                  currentPage={page - 1}
                  totalPages={availablePages}
                  totalItems={availableItems}
                  pageSize={pageSize}
                  singlePageText={(count) => t('common.pager.singlePage', { count })}
                  multiplePageText={(start, end, total) =>
                    t('common.pager.multiplePage', { start, end, total })
                  }
                  onChange={(pageIndex) => fetchListAdmins({ page: pageIndex + 1 })}
                />
              </div>
            </section>
      </Main>
      {(showCreateModal || edittingUser) && (
        <CreateAdmin
          onClose={handleCloseForm}
          admin={edittingUser}
        />
      )}
      {showProfiles && (
        <ProfilesModal
          onClose={() => setShowProfiles(null)}
          profiles={showProfiles}
        />
      )}
    </>
  )
}

export default AdminsScreen
