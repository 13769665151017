// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaasAdminForm__radio{display:flex;gap:32px;align-items:center}.SaasAdminForm__radio .lc-radio-control{display:flex;align-items:center}.SaasAdminForm .custom-table-body .lc-form-control-wrapper{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/app/pages/SaasAdmins/Form/styles.scss"],"names":[],"mappings":"AASE,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CACA,wCACE,YAAA,CACA,kBAAA,CAIF,2DACE,eAAA","sourcesContent":["\n              @import './src/styles/abstracts/variables';\n              @import '~@liveconnect/bootstrap/variables';\n              @import '~@liveconnect/bootstrap/mixins';\n              @import '~bootstrap/scss/functions';\n              @import '~bootstrap/scss/variables';\n              @import '~bootstrap/scss/mixins';\n              \n.SaasAdminForm {\n  &__radio {\n    display: flex;\n    gap: 32px;\n    align-items: center;\n    .lc-radio-control {\n      display: flex;\n      align-items: center;\n    }\n  }\n  .custom-table-body {\n    .lc-form-control-wrapper {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SaasAdminForm__radio": `SaasAdminForm__radio`,
	"lc-radio-control": `lc-radio-control`,
	"SaasAdminForm": `SaasAdminForm`,
	"custom-table-body": `custom-table-body`,
	"lc-form-control-wrapper": `lc-form-control-wrapper`
};
export default ___CSS_LOADER_EXPORT___;
