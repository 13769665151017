import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    region: yup
      .string()
      .label(t('license.cometchat.form.region.label'))
      .required(({ label }) => t('validations.required', { label })),
    appId: yup
      .string()
      .label(t('license.cometchat.form.app.label'))
      .required(({ label }) => t('validations.required', { label })),
    apiKey: yup
      .string()
      .label(t('license.cometchat.form.api.label'))
      .required(({ label }) => t('validations.required', { label })),
      defaultRole: yup
      .string()
      .label(t('license.cometchat.form.role.label'))
      .required(({ label }) => t('validations.required', { label })),
    defaultAvatar: yup
      .string()
      .label(t('license.cometchat.form.avatar.label'))
      .required(({ label }) => t('validations.required', { label })),
  })
}
