import { FC, useState } from 'react'
import { Link, useMatch, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Icon } from '@liveconnect/icons'
import classNames from 'classnames'

import { useCustomRouter } from '../../utils/extractParams'
import Restricted from '../Restricted'
import { navbarItems } from './items'
import { tenantItems } from './tenantItems'
import { NavbarItem } from './types'
import WebIcon from '../../components/WebIcon'

import './styles.scss'

type NavbarProps = {
  rootClassName?: string
  menuItems?: NavbarItem[]
}

const Navbar: FC<NavbarProps> = ({ rootClassName = 'Menu', menuItems }) => {
  const { basePath } = useCustomRouter()
  const [openSubmenu, setOpenSubmenu] = useState<string>('')
  const { tenantId } = useParams()

  const handleClickGroup = (key: string) => {
    if (key === openSubmenu) setOpenSubmenu('')
    else setOpenSubmenu(key)
  }

  const handleMouseLeave = () => {
    setOpenSubmenu('')
  }

  return (
    <ul className={rootClassName} onMouseLeave={handleMouseLeave}>
      {(menuItems || tenantId ? tenantItems : navbarItems).map(
        (item, index) => {
          if (item && item.path && tenantId)
            item = { ...item, path: item.path.replace(':tenantId', tenantId) }
          return (
            <Restricted
              to={item.permissions}
              mustComply={item.mustComply}
              key={index}
            >
              <li className={`${rootClassName}__${index}`}>
                {item.submenu ? (
                  <NavbarGroup
                    item={item}
                    basePath={basePath}
                    rootClassName={rootClassName}
                    onClick={() => handleClickGroup(item.key)}
                    isOpen={openSubmenu === item.key}
                  />
                ) : (
                  <NavbarLink
                    item={item}
                    basePath={basePath}
                    rootClassName={rootClassName}
                  />
                )}
              </li>
            </Restricted>
          )
        }
      )}
    </ul>
  )
}

interface NavbarItemProps {
  basePath: string
  item: NavbarItem
  rootClassName: string
}

const NavbarLink: FC<NavbarItemProps> = ({ basePath, item, rootClassName }) => {
  const { t } = useTranslation()
  const path = `${basePath}${item.path}`
  const active = useMatch({ path, end: item.exact })

  return (
    <Link
      to={path}
      className={classNames(`${rootClassName}__item`, {
        active,
        disabled: item.disabled,
      })}
    >
      {item.icon && (
        <div className={`${rootClassName}__icon`}>
          <WebIcon name={item.icon as string} />
        </div>
      )}
      <div className={`${rootClassName}__text`}>{t(item.trnsKey)}</div>
    </Link>
  )
}
interface NavbarGroupProps extends NavbarItemProps {
  onClick?: () => void
  isOpen: boolean
}

const NavbarGroup: FC<NavbarGroupProps> = ({
  basePath,
  item,
  rootClassName,
  onClick,
  isOpen,
}) => {
  const { t } = useTranslation()
  const actives = item.submenu?.map((item) => {
    const path = `${basePath}${item.path}`
    return !!useMatch({ path, end: item.exact })
  })
  const active = actives?.includes(true)

  return (
    <div>
      <div
        className={classNames(`${rootClassName}__item`, {
          active,
          disabled: item.disabled,
        })}
        onClick={onClick}
      >
        <div className={`${rootClassName}__icon`}>
          <WebIcon
            name={item.icon as string}
            className={classNames({ active })}
          />
        </div>
        <div className={`${rootClassName}__text`}>{t(item.trnsKey)}</div>
        <div className={`${rootClassName}__chevron`}>
          {isOpen ? (
            <Icon name="keyboard_arrow_up" />
          ) : (
            <Icon name="keyboard_arrow_down" />
          )}
        </div>
      </div>
      {isOpen && <Navbar menuItems={item.submenu} rootClassName="Submenu" />}
    </div>
  )
}

export default Navbar
