import { useParams } from 'react-router'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseLicensesResult, EmailLicense, CometchatLicense } from './types'
import { setEmail, setCometchat, clearLicenses } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useLicenses = (): UseLicensesResult => {
  const dispatch = useAppDispatch()
  const { tenantId } = useParams()

  const { email, cometchat } = useAppSelector((state) => state.licenses)
  const { get, put } = useFetch()

  const endpoint = `tenants/${tenantId}/configuration`

  const fetchEmail = async () => {
    const response: EmailLicense | undefined = await get({
      endpoint: `${endpoint}/email`,
    })
    response && dispatch(setEmail(response))
  }

  const updateEmail = async (body: EmailLicense) => {
    const response: EmailLicense | undefined = await put({
      endpoint: `${endpoint}/email`,
      body,
    })
    response && dispatch(setEmail({ ...body }))
  }

  const fetchCometchat = async () => {
    const response: CometchatLicense | undefined = await get({
      endpoint: `${endpoint}/chat`,
    })
    response && dispatch(setCometchat(response))
  }

  const updateCometchat = async (body: CometchatLicense) => {
    const response: CometchatLicense | undefined = await put({
      endpoint: `${endpoint}/chat`,
      body,
    })
    response && dispatch(setCometchat({ ...body }))
  }

  return {
    email,
    cometchat,
    fetchCometchat,
    updateCometchat,
    fetchEmail,
    updateEmail,
    clearLicenses: () => dispatch(clearLicenses()),
  }
}

export default useLicenses
