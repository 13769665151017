import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseTenantsResult,
  TenantRequestParams,
  TenantListResponse,
  CreateTenantForm,
  TenantDetail,
  TenantDetailForm,
  Tenant,
} from './types'
import {
  setList,
  clearList,
  showLoader,
  hideLoader,
  toggleEnable,
  setDetail,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const useTenants = (): UseTenantsResult => {
  const dispatch = useAppDispatch()
  const {
    detail,
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.tenants)
  const { get, post, put } = useFetch()

  const endpoint = 'tenants'

  const parseParams = (requestParams?: TenantRequestParams) => {
    const params: TenantRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getList = async (params?: TenantRequestParams) => {
    const response: TenantListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchList = async (params?: TenantRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseParams(params)
    const response: TenantListResponse | undefined = await getList(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const getUnpagedList = async (search?: string) => {
    const response: Tenant[] | undefined = await get({
      endpoint: `${endpoint}/get-all`,
      params: { search },
    })
    return response
  }

  const toggleTenantEnabled = async (id: string, enabled: boolean) => {
    await post({ endpoint: `${endpoint}/${id}/enable`, body: { enabled } })
    dispatch(
      toggleEnable({
        enabled,
        id,
      })
    )
  }

  const createTenant = async (tenant: CreateTenantForm) => {
    await post({ endpoint, body: tenant })
  }

  const fetchDetail = async (id: string) => {
    const response: TenantDetail | undefined = await get({
      endpoint: `${endpoint}/${id}`,
    })
    response && dispatch(setDetail(response))
  }

  const updateDetail = async (id: string, body: TenantDetailForm) => {
    const response: TenantDetail | undefined = await put({
      endpoint,
      id,
      body,
    })
    response && dispatch(setDetail(response))
  }

  return {
    detail,
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    toggleTenantEnabled,
    createTenant,
    fetchDetail,
    updateDetail,
    getUnpagedList,
  }
}

export default useTenants
