import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseTenantUrlsResult, TenantUrl, TenantUrlUpdateItem } from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'
import { useParams } from 'react-router'

const useTenantUrls = (): UseTenantUrlsResult => {
  const { tenantId } = useParams()
  const dispatch = useAppDispatch()
  const { list, isLoaded } = useAppSelector((state) => state.tenantUrls)
  const { get, put } = useFetch()

  const endpoint = `tenants/${tenantId}/urls`

  const getList = async () => {
    const response: TenantUrl[] | undefined = await get({
      endpoint,
    })
    return response
  }

  const fetchList = async () => {
    dispatch(showLoader())
    const response: TenantUrl[] | undefined = await getList()
    response && dispatch(setList(response))
    dispatch(hideLoader())
  }

  const updateUrls = async (body: TenantUrlUpdateItem[]) => {
    await put({ endpoint, body })
  }

  return {
    list,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    updateUrls,
  }
}

export default useTenantUrls
