import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormCheckbox,
  FormProvider,
  FormRadio,
  FormTextInput,
  Loader,
} from '@liveconnect/components'
import { ColumnDef } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import ContentTable from '../../../components/ContentTable'
import FormActions from '../../../components/FormActions'
import { Main } from '../../../components/Main'
import {
  SaasAdminCreateForm,
  SaasAdminProfile,
} from '../../../core/saasAdmins/types'
import useSaasAdmins from '../../../core/saasAdmins/useSaasAdmins'
import { Tenant } from '../../../core/tenants/types'
import useTenants from '../../../core/tenants/useTenants'
import useNotifications from '../../../utils/notifications/useNotifications'
import { buildValidationSchema } from './validations'

import './styles.scss'

const SaadAdminForm = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useNotifications()
  const { getUnpagedList } = useTenants()
  const { detail, fetchDetail, createAdmin, updateAdmin, fetchList } =
    useSaasAdmins()
  const [tenants, setTenants] = useState<Tenant[]>([])
  const methods = useForm<SaasAdminCreateForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      profile: SaasAdminProfile.SaaS_GlobalAdmin,
    },
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = methods

  const profile = watch('profile')
  const tenantsDic = watch('tenantsDic')

  const initTenantList = async (search?: string) => {
    const _tenants = await getUnpagedList(search)
    _tenants && setTenants(_tenants)
  }

  const renderChekboxCell = (tenant: Tenant) => {
    return (
      <FormCheckbox
        name={`tenantsDic.${tenant.id}`}
        control={control}
        label=""
      />
    )
  }

  const columns: ColumnDef<Tenant>[] = [
    {
      id: 'checkbox-table-column',
      maxSize: 25,
      cell: ({ row }) => renderChekboxCell(row.original),
    },
    {
      accessorKey: 'name',
      header: t('saasAdmins.form.table.header.name'),
    },
  ]

  const checkboxValidation = () =>
    profile === SaasAdminProfile.SaaS_ClientAdmin
      ? Object.keys(tenantsDic || {}).some((key) => tenantsDic[key])
      : true

  const handleCancel = () => {
    navigate('/saas-admins')
  }

  const onSubmit = async (data: SaasAdminCreateForm) => {
    try {
      if (profile === SaasAdminProfile.SaaS_ClientAdmin) {
        data.tenantsIds = Object.keys(tenantsDic || {}).filter(
          (key) => tenantsDic[key]
        )
      }
      if (id) {
        delete data.email
        await updateAdmin(id, data)
      } else {
        await createAdmin(data)
      }
      notify.success(t(`saasAdmins.${id ? 'edit.' : ''}form.submit.success`))
      fetchList({ page: 1 })
      navigate('/saas-admins')
    } catch (e) {
      notify.error(t(`saasAdmins.${id ? 'edit.' : ''}form.submit.error`))
    }
  }

  useEffect(() => {
    if (id && detail) {
      const tenantsDic = {}
      for (const item of detail.tenantsIds) {
        tenantsDic[item] = true
      }
      reset({ ...detail, tenantsDic })
    }
  }, [detail])

  useEffect(() => {
    id && fetchDetail(id)
  }, [id])

  useEffect(() => {
    initTenantList()
  }, [])

  return (
    <Main ariaLabelledby="identity-title" className="SaasAdminForm">
      <div className="sticky-container">
        <div className="d-flex justify-content-between mb-5">
          <div>
            <h1 id="identity-title" className="h3">
              {t(`saasAdmins.${id ? 'edit.' : ''}form.title`)}
            </h1>
            <p>{t(`saasAdmins.${id ? 'edit.' : ''}form.hint`)}</p>
          </div>
        </div>
        <FormProvider methods={methods}>
          <div className="row">
            <div className="h4 mb-4">{t('saasAdmins.form.data')}</div>
          </div>
          <div className="row">
            <div className="col-9">
              <FormTextInput
                control={control}
                name="email"
                label={t('saasAdmins.form.email.label')}
                placeholder={t('saasAdmins.form.email.placeholder')}
                type="text"
                disabled={!!id}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-9">
              <label htmlFor="profile" className="form-label">
                {t('saasAdmins.form.profile.label')}
              </label>
              <div className="SaasAdminForm__radio">
                <FormRadio
                  control={control}
                  name="profile"
                  options={Object.keys(SaasAdminProfile).map((item) => ({
                    value: item,
                    label: t(`saasAdmins.list.profile.${item}`),
                  }))}
                />
              </div>
            </div>
          </div>
          {profile === SaasAdminProfile.SaaS_ClientAdmin && (
            <div className="mt-5">
              <div className="h4">{t('saasAdmins.form.table.section')}</div>
              <ContentTable
                title={t('saasAdmins.form.table.title')}
                columns={columns}
                data={tenants}
                emptyText={t('saasAdmins.form.table.emptyList')}
                onSearch={initTenantList}
                searchPlaceholder={t('common.search')}
              />
            </div>
          )}
        </FormProvider>
      </div>
      <FormActions>
        <div>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-outline-primary mr-2"
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={
              isSubmitting || !isValid || !isDirty || !checkboxValidation()
            }
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? <Loader /> : t(`common.${id ? 'save' : 'add'}`)}
          </button>
        </div>
      </FormActions>
    </Main>
  )
}

export default SaadAdminForm
