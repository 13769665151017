import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, FormTextInput, Loader } from '@liveconnect/components'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormActions from '../../../components/FormActions'
import { EmailLicense } from '../../../core/licenses/types'
import useLicenses from '../../../core/licenses/useLicenses'
import useNotifications from '../../../utils/notifications/useNotifications'
import { buildValidationSchema } from './validations'

const EmailLicenseForm = () => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { email, fetchEmail, updateEmail } = useLicenses()
  const methods = useForm<EmailLicense>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = methods

  const handleCancel = () => {
    email && reset(email)
  }

  const onSubmit = async (data: EmailLicense) => {
    try {
      await updateEmail(data)
      notify.success(t('license.email.form.success'))
    } catch (e) {
      notify.error(t('license.email.form.error'))
    }
  }

  useEffect(() => {
    email && reset(email)
  }, [email])

  useEffect(() => {
    fetchEmail()
  }, [])

  return (
    <div>
      <div className="sticky-container">
        <FormProvider methods={methods}>
          <div className="row">
            <div className="col-6">
              <FormTextInput
                control={control}
                name="fromEmail"
                label={t('license.email.form.from.label')}
                placeholder={t('license.email.form.from.placeholder')}
                type="text"
                required={true}
              />
            </div>
            <div className="col-6">
              <FormTextInput
                control={control}
                name="fromDisplayName"
                label={t('license.email.form.display.label')}
                placeholder={t('license.email.form.display.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <FormTextInput
                control={control}
                name="providerEmailApiKey"
                label={t('license.email.form.api.label')}
                placeholder={t('license.email.form.api.placeholder')}
                type="text"
                required={true}
              />
            </div>
          </div>
        </FormProvider>
      </div>
      <FormActions>
        <div>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-outline-primary mr-2"
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={isSubmitting || !isValid || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            {isSubmitting ? <Loader /> : t(`common.save`)}
          </button>
        </div>
      </FormActions>
    </div>
  )
}

export default EmailLicenseForm
