import { useNavigate } from 'react-router'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UsePermissionsResult,
  Permission,
  ComplimentType,
  PermissionListResponse,
} from './types'
import { setList } from './store'
import useFetch from '../../utils/fetch/useFetch'

const usePermissions = (): UsePermissionsResult => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { list, isLoaded } = useAppSelector((state) => state.permissions)
  const { get } = useFetch()

  const endpoint = 'permissions'

  const getList = async () => {
    const response: PermissionListResponse | undefined = await get({
      endpoint,
    })
    return response
  }

  const fetchList = async () => {
    const response: PermissionListResponse | undefined = await getList()
    if (!response?.hasProfile) navigate('/access-denied')
    if (response) {
      const actions = response.tenants.map((item) => `tenant-${item}`)
      response.isGlobalAdmin && actions.push('global-admin')
      dispatch(setList({ list: actions }))
    }
  }

  const checkPermissions = (to: Permission[], mustComply?: ComplimentType) => {
    if (to.length === 0) return true
    if (mustComply === ComplimentType.all) {
      return to.every((item) => list.includes(item))
    }
    return to.some((item) => list.includes(item))
  }

  return {
    list,
    isLoaded,
    fetchList,
    checkPermissions,
  }
}

export default usePermissions
