import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'
import { TenantUrl } from '../../core/tenants/types'

export function buildValidationSchema(
  t: TFunction,
  list: Array<TenantUrl>
): yup.AnyObjectSchema {
  const validation: Record<
    string,
    RequiredStringSchema<string | undefined, AnyObject>
  > = {}

  for (const item of list || []) {
    validation[item.code] = yup
      .string()
      .label(t('tenant.create.name.label'))
      .url(t('validations.url'))
      .required(t('validations.required', { label: '' }))
  }

  return yup.object().shape(validation)
}
