import { ToggleControl } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import WebIcon from '../../../components/WebIcon'
import { Tenant } from '../../../core/tenants/types'
import useTenants from '../../../core/tenants/useTenants'
import useUi from '../../../core/ui/useUi'
import useNotifications from '../../../utils/notifications/useNotifications'

import './styles.scss'

interface TenantListItemProps {
  tenant: Tenant
}

const TenantListItem: FC<TenantListItemProps> = ({ tenant }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useNotifications()
  const { showConfirmation } = useUi()
  const { toggleTenantEnabled } = useTenants()

  const handleConfirmEnable = (enabled: boolean) => {
    const type = enabled ? 'enable' : 'disable'
    showConfirmation({
      title: t(`tenant.list.item.${type}.confirm.title`),
      subtitle: t(`tenant.list.item.${type}.confirm.subtitle`),
      text: t(`tenant.list.item.${type}.confirm.text`),
      confirmText: t(`tenant.list.item.${type}.confirm.button`),
      iconName: 'report_problem',
      onConfirm: () => handleToggleEnable(enabled, type),
      cancelText: t('common.cancel'),
    })
  }

  const getErrorMessage = (text: string) => {
    let type = ''
    switch (text) {
      case 'Tenant email service is not configured':
        type = 'email'
        break
      case 'Tenant chat service is not configured':
        type = 'chat'
        break
      case 'Tenant IDS service is not configured':
        type = 'ids'
        break
    }
    return t(`tenant.list.item.confirm.error.${type}.text`)
  }

  const handleToggleEnable = async (enabled: boolean, type: string) => {
    try {
      await toggleTenantEnabled(tenant.id, enabled)
      notify.success(t(`tenant.list.item.${type}.success`))
    } catch (e) {
      const textArr = e.body.map((item) => getErrorMessage(item.message))
      showConfirmation({
        title: t(`tenant.list.item.confirm.error.title`),
        text: (
          <div>
            {textArr.map((item: string) => (
              <p key={item}>{item}</p>
            ))}
          </div>
        ),
        confirmText: t('common.accept'),
        iconName: 'report_problem',
      })
      // notify.error(t(`tenant.list.item.${type}.error`))
    }
  }

  return (
    <div className="TenantListItem" onClick={() => navigate(`./${tenant.id}`)}>
      <div className="TenantListItem__icon">
        <WebIcon name={'menu_tenants'} />
      </div>
      <div className="TenantListItem__info">
        <div className="TenantListItem__info__name">{tenant.name}</div>
        <div
          className="TenantListItem__info__toggle"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <ToggleControl
            className="EventRow__header__actions__publish"
            label={t('tenant.list.item.enabled')}
            name={'enabled'}
            value={tenant.enabled}
            onChange={handleConfirmEnable}
          />
        </div>
      </div>
      <div className="TenantListItem__chevron">
        <Icon name="chevron_right" />
      </div>
    </div>
  )
}

export default TenantListItem
