import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EmailLicense, CometchatLicense, LicensesState } from './types'

const initialState: LicensesState = {
  email: null,
  cometchat: null,
}

export const slice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<EmailLicense>) => {
      state.email = action.payload
    },
    setCometchat: (state, action: PayloadAction<CometchatLicense>) => {
      state.cometchat = action.payload
    },
    clearLicenses: () => initialState,
  },
})

export const { setEmail, setCometchat, clearLicenses } = slice.actions

export default slice.reducer
