import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IdsConfig, IdsConfigState } from './types'

const initialState: IdsConfigState = {
  detail: null,
}

export const slice = createSlice({
  name: 'idsConfig',
  initialState,
  reducers: {
    setDetail: (state, action: PayloadAction<IdsConfig>) => {
      state.detail = action.payload
    },
    clearDetail: () => initialState,
  },
})

export const { setDetail, clearDetail } = slice.actions

export default slice.reducer
