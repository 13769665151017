import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseModulesResult, Module } from './types'
import {
  setList,
  clearList,
  showLoader,
  hideLoader,
  toggleActive as toggleActiveModule,
} from './store'
import useFetch from '../../utils/fetch/useFetch'
import { useParams } from 'react-router'

const useModules = (): UseModulesResult => {
  const { tenantId } = useParams()
  const dispatch = useAppDispatch()
  const { list, isLoaded } = useAppSelector((state) => state.modules)
  const { get, put } = useFetch()

  const endpoint = `tenants/${tenantId}/modules`

  const getList = async () => {
    const response: Module[] | undefined = await get({
      endpoint,
    })
    return response
  }

  const fetchList = async () => {
    dispatch(showLoader())
    const response: Module[] | undefined = await getList()
    response && dispatch(setList(response))
    dispatch(hideLoader())
  }

  const toggleActivate = async (code: string, isActive: boolean) => {
    try {
      await put({ endpoint, id: code, body: { isActive } })
      dispatch(toggleActiveModule({ code, isActive }))
    } catch (e) {
      throw e
    }
  }

  return {
    list,
    isLoaded,
    fetchList,
    clearList: () => dispatch(clearList()),
    toggleActivate,
  }
}

export default useModules
