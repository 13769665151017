export type Permission = string

export interface PermissionListResponse {
  hasProfile: boolean
  isGlobalAdmin: boolean
  tenants: string[]
}

export interface PermissionsListPayload {
  list: Permission[]
}

export interface PermissionsState {
  list: Permission[]
  isLoaded: boolean
}

export enum ComplimentType {
  one = 'one',
  all = 'all',
}

export interface UsePermissionsResult {
  list: Array<Permission>
  isLoaded: boolean
  fetchList: () => void
  checkPermissions: (to: Permission[], mustComply?: ComplimentType) => boolean
}
