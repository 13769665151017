import { FC } from 'react'
import { Route, Navigate, Routes, useParams } from 'react-router-dom'

import NotFound from '../pages/error/NotFound'
import PrivateRoute from './PrivateRoute'
import HomeScreen from '../pages/Home'
import TenantsPage from '../pages/Tenants'
import TenantRouter from './TenantRouter'
import RestrictedRoute from '../containers/Restricted/RestrictedRoute'
import SaasAdminRouter from './SaasAdminRouter'

const TenantRouteElement = () => {
  const { tenantId } = useParams()
  return (
    <RestrictedRoute
      permissions={['global-admin', `tenant-${tenantId}`]}
      element={<TenantRouter />}
    />
  )
}

const SaasRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Navigate to="home" />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route
          path="/saas-admins/*"
          element={
            <RestrictedRoute
              permissions={['global-admin']}
              element={<SaasAdminRouter />}
            />
          }
        />
        <Route path="/tenants" element={<TenantsPage />} />
        <Route path="/tenants/:tenantId/*" element={<TenantRouteElement />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default SaasRouter
