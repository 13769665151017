export enum SaasAdminProfile {
  SaaS_GlobalAdmin = 'SaaS_GlobalAdmin',
  SaaS_ClientAdmin = 'SaaS_ClientAdmin',
}

export interface SaasAdmin {
  id: string
  email: string
  profile: SaasAdminProfile
}

export interface SaasAdminDetail {
  id: string
  email: string
  profile: SaasAdminProfile
  tenantsIds: string[]
}

export interface SaasAdminState {
  detail: SaasAdminDetail | null
  list: SaasAdmin[]
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
  search: string
  isLoaded: boolean
}

export interface SaasAdminRequestParams {
  page: number
  page_size?: number
  search?: string
}

export interface SaasAdminListPayload {
  list: SaasAdmin[]
  page: number
  pageSize: number
  availablePages: number
  availableItems: number
  search: string
}

export interface SaasAdminListResponse {
  items: SaasAdmin[]
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface SaasAdminCreateForm {
  email: string
  profile: SaasAdminProfile
  tenantsIds: string[]
  tenantsDic?: Record<string, boolean>
}

export interface UseSaasAdminsResult {
  list: SaasAdmin[]
  page: number
  availablePages: number
  availableItems: number
  pageSize: number
  search: string
  isLoaded: boolean
  detail: SaasAdminDetail | null
  fetchList: (params?: SaasAdminRequestParams) => void
  clearList: () => void
  fetchDetail: (id: string) => void
  deleteAdmin: (id: string) => void
  createAdmin: (data: SaasAdminCreateForm) => void
  updateAdmin: (id: string, data: SaasAdminCreateForm) => void
}
