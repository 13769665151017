import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import NotFound from '../pages/error/NotFound'
import SaasAdmins from '../pages/SaasAdmins'
import SaadAdminForm from '../pages/SaasAdmins/Form'

const SaasAdminRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SaasAdmins />} />
      <Route path="/create" element={<SaadAdminForm />} />
      <Route path="/edit/:id" element={<SaadAdminForm />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default SaasAdminRouter
