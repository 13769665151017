import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import { Settings, SettingsState } from './types'

const initialState: SettingsState = {
  settings: null,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<Settings>) => {
      state.settings = action.payload
    },
  },
})

export const { setSettings } = settingsSlice.actions

export const selectSettings = (state: RootState) => state.settings

export default settingsSlice.reducer
