import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    name: yup
      .string()
      .label(t('tenant.create.name.label'))
      .max(50, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 50 })
      )
      .required(({ label }) => t('validations.required', { label })),
    slug: yup
      .string()
      .label(t('tenant.create.slug.label'))
      .required(({ label }) => t('validations.required', { label }))
      .max(50, ({ label }) =>
        t('validations.maxLength', { label, maxLength: 50 })
      )
      .matches(
        /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
        t('tenant.create.slug.regexError')
      ),
  })
}
