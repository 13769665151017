import { NavbarItem } from './types'

export const navbarItems: NavbarItem[] = [
  {
    key: 'admins',
    trnsKey: 'navMenu.saasAdmins',
    icon: 'menu_admin',
    path: '/saas-admins',
    exact: false,
    disabled: false,
    permissions: ['global-admin'],
  },
  {
    key: 'tenants',
    trnsKey: 'navMenu.tenants',
    icon: 'menu_tenants',
    path: '/tenants',
    exact: false,
    disabled: false,
    permissions: [],
  },
]
