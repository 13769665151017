import { FC } from 'react'

import { Main } from '../../components/Main'

import './styles.scss'

const HomeScreen: FC = () => {
  return <Main ariaLabelledby="Home">SAAS ADMIN</Main>
}

export default HomeScreen
