import { useEffect, useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { Pagination, Tag } from '@liveconnect/components'
import { useTranslation } from 'react-i18next'
import ContentTable from '../../components/ContentTable'
import { Main } from '../../components/Main'
import useSaasAdmins from '../../core/saasAdmins/useSaasAdmins'
import { SaasAdmin } from '../../core/saasAdmins/types'
import useAuth from '../../core/auth/useAuth'
import useUi from '../../core/ui/useUi'
import useNotifications from '../../utils/notifications/useNotifications'
import { useNavigate } from 'react-router'

const SaasAdmins = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notifiy = useNotifications()
  const { user } = useAuth()
  const { showConfirmation } = useUi()
  const {
    list,
    page,
    availablePages,
    availableItems,
    pageSize,
    isLoaded,
    fetchList,
    deleteAdmin,
  } = useSaasAdmins()

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('saasAdmins.list.item.delete.confirm.title'),
      subtitle: t('saasAdmins.list.item.delete.confirm.subtitle'),
      text: t('saasAdmins.list.item.delete.confirm.text'),
      confirmText: t('common.remove'),
      iconName: 'report_problem',
      onConfirm: () => handleDelete(id),
      cancelText: t('common.cancel'),
    })
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteAdmin(id)
      await fetchList({ page: 1 })
      notifiy.success(t('saasAdmins.list.delete.success'))
    } catch (e) {
      notifiy.success(t('saasAdmins.list.delete.error'))
    }
  }

  const actions = (row: SaasAdmin) => {
    return [
      {
        onClick: () => {
          navigate(`./edit/${row.id}`)
        },
        label: t('saasAdmins.list.table.action.edit'),
        icon: 'edit',
        isHidden: row.id === user?.profile.sub,
      },
      {
        onClick: () => handleConfirmDelete(row.id),
        label: t('saasAdmins.list.table.action.delete'),
        icon: 'delete_outline',
        isHidden: row.id === user?.profile.sub,
      },
    ]
  }

  const renderProfileCell = (row: SaasAdmin) => {
    if (row.profile === 'SaaS_GlobalAdmin') {
      return <Tag>{t(`saasAdmins.list.profile.${row.profile}`)}</Tag>
    }
    return <Tag type="dark">{t(`saasAdmins.list.profile.${row.profile}`)}</Tag>
  }

  const columns: ColumnDef<SaasAdmin>[] = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: t('saasAdmins.list.table.email'),
      },
      {
        accessorKey: 'profile',
        header: t('saasAdmins.list.table.type'),
        cell: ({ row }) => renderProfileCell(row.original),
      },
    ],
    []
  )

  useEffect(() => {
    fetchList({ page: 1 })
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="SaasAdmins">
      <div className="d-flex justify-content-between">
        <div>
          <h1 id="user-list-title" className="h3">
            {t('saasAdmins.list.title')}
          </h1>
          <p>{t('saasAdmins.list.hint')}</p>
        </div>
        <button
          className="SaasAdmins__refresh btn btn-primary h-100"
          onClick={() => navigate('./create')}
        >
          {t('saasAdmins.list.add')}
        </button>
      </div>
      <section className="mt-3">
        <div className="SaasAdmins__list">
          <ContentTable
            columns={columns}
            data={list}
            actions={actions}
            title={t('saasAdmins.list.table.title')}
            emptyText={t('saasAdmins.list.table.emptyList')}
            onSearch={(s: string) => {
              fetchList({ page: 1, search: s })
            }}
            searchPlaceholder={t('saasAdmins.list.search')}
            isLoaded={isLoaded}
          />
          <Pagination
            currentPage={page - 1}
            totalPages={availablePages}
            totalItems={availableItems}
            pageSize={pageSize}
            singlePageText={(count) => t('common.pager.singlePage', { count })}
            multiplePageText={(start, end, total) =>
              t('common.pager.multiplePage', { start, end, total })
            }
            onChange={(pageIndex) => fetchList({ page: pageIndex + 1 })}
          />
        </div>
      </section>
    </Main>
  )
}

export default SaasAdmins
