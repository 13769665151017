import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormProvider,
  FormTextInput,
  FormMultiSelectTree,
} from '@liveconnect/components'
import debounce from 'lodash.debounce'

import useNotifications from '../../../utils/notifications/useNotifications'
import useAdmins from '../../../core/admins/useAdmins'

import { buildValidationSchema } from './validations'

import {
  Admin,
  CreateAdminType,
  Profile,
  FindAdminResponse
} from '../../../core/admins/types'
import { FetchError } from '../../../utils/fetch/types'
import useUi from '../../../core/ui/useUi'

import './styles.scss'

interface CreateAdminProps {
  admin?: Admin
  eventId: string
  onClose: () => void
}

interface Option {
  value: string
  label: string
}

const CreateEventAdmin: FC<CreateAdminProps> = ({
  admin,
  eventId,
  onClose,
}) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const { createAdmin, updateAdmin, fetchList, getProfiles, findAdminByEmail } = useAdmins()
  const { showConfirmation } = useUi()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [profileOptions, setProfileOptions] = useState<Option[]>([])
  const [isReadonly, setIsReadonly] = useState<boolean>(false)

  const methods = useForm<CreateAdminType>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: admin
      ? {
          email: admin.email,
          name: admin.name,
          surname: admin.surname ?? '',
          profiles: admin.profiles.map((item: Profile) => item.id),
        }
      : {
          email: '',
          name: '',
          surname: '',
          profiles: [],
        },
  })
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    getFieldState,
    trigger,
    formState: { isValid, isDirty },
  } = methods

  const initProfiles = async () => {
    const profiles = await getProfiles()
    !admin && setDefaultProfile(profiles)
    initProfileOptions(profiles)
  }

  const setDefaultProfile = (profiles: Profile[] | undefined) => {
    const defaultProfile = (profiles || []).find(
      (item: Profile) => item.isDefault
    )
    defaultProfile && setValue('profiles', [defaultProfile.id])
  }

  const initProfileOptions = (profiles: Profile[] | undefined) => {
    const parsedProfiles = (profiles || []).map(
      (item: Profile) => ({
        label: item.name,
        value: item.id,
      })
    )
    setProfileOptions(parsedProfiles)
  }

  const handleCancel = () => {
    if (!isDirty) onClose()
    else {
      showConfirmation({
        title: t('common.warning'),
        subtitle: t('modal.cancel.generic.subtitle'),
        text: t('modal.cancel.generic.text'),
        confirmText: t('common.yes'),
        cancelText: t('common.no'),
        iconName: 'report_problem',
        onConfirm: onClose,
      })
    }
  }

  const onSubmit = async (newEventAdmin: CreateAdminType) => {
    setIsSubmitting(true)
    try {
      if (admin) {
        await updateAdmin(admin.id, newEventAdmin.profiles)
      } else {
        await createAdmin(newEventAdmin)
      }
      await fetchList()
      notify.success(t('eventAdminsModal.create.modal.successFeedback'))
      onClose()
    } catch (error: unknown) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        setError('email', {
          type: 'custom',
          message: t(`validations.ENTITY_CONFLICT`, {
            label: t(`eventAdminsModal.create.modal.email`).toLowerCase(),
          }),
        })
      } else {
        onClose()
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const fillFormData = (response?: FindAdminResponse) => {
    setValue('name', response?.name || '')
    setValue('surname', response?.surname || '')
    setIsReadonly(response ? true : false)
    trigger()
  }

  const onSearch = useCallback(async () => {
    if (!!!getFieldState('email').error) {
      const email = watch('email')
      try {
        const response: FindAdminResponse | undefined = await findAdminByEmail(
          email
        )
        fillFormData(response)
      } catch (error: unknown) {
        const fetchError = error as FetchError
        if (fetchError.status === 404) {
          fillFormData()
        }
      }
    }
  }, [findAdminByEmail])

  const debouncedOnSearch = useMemo(() => debounce(onSearch, 500), [onSearch])

  useEffect(() => {
    initProfiles()
  }, [])

  return (
    <Modal
      show={true}
      onHide={handleCancel}
      backdrop="static"
      data-keyboard="false"
      size="lg"
      className="CreateEventAdmin"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t(`eventAdminsModal.${admin ? 'edit' : 'create'}.modal.title`)}
        </Modal.Title>
      </Modal.Header>
      <FormProvider methods={methods}>
        <Modal.Body>
          <div className="d-flex CreateEventAdmin__legend">
            <div className="bd-highlight">{t('common.required')}</div>
          </div>
          <div className="mt-3">
            <h5>{t(`eventAdminsModal.create.modal.data.title`)}</h5>
          </div>
          <div className="row">
            <div className="col-12">
              <FormTextInput
                control={control}
                name="email"
                label={t('eventAdminsModal.create.modal.email')}
                placeholder={t(
                  'eventAdminsModal.create.modal.email.placeholder'
                )}
                type="text"
                required={true}
                disabled={!!admin}
                onChange={debouncedOnSearch}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <FormTextInput
                control={control}
                name="name"
                label={t('eventAdminsModal.create.modal.name')}
                placeholder={t(
                  'eventAdminsModal.create.modal.name.placeholder'
                )}
                type="text"
                required={true}
                disabled={!!admin || isReadonly}
              />
            </div>
            <div className="col-6">
              <FormTextInput
                control={control}
                name="surname"
                label={t('eventAdminsModal.create.modal.surname')}
                placeholder={t(
                  'eventAdminsModal.create.modal.surname.placeholder'
                )}
                type="text"
                disabled={!!admin || isReadonly}
              />
            </div>
          </div>
          <div className="mt-3">
            <h5>{t(`eventAdminsModal.create.modal.profiles.title`)}</h5>
          </div>
          <div className="row">
            <div className="col-6">
              <FormMultiSelectTree
                control={control}
                name="profiles"
                placeholder={t(
                  'eventAdminsModal.create.modal.profiles.placeholder'
                )}
                options={profileOptions}
                label={t('eventAdminsModal.create.modal.profiles')}
                required={true}
                placeholderInfo={(number) =>
                  t('eventAdminsModal.create.modal.profiles.info', {
                    count: number,
                  })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={handleCancel}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            {t(`common.${admin ? 'save' : 'add'}`)}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default CreateEventAdmin
