import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import classNames from 'classnames'

import { Icon } from '@liveconnect/icons'

import useUi from '../../core/ui/useUi'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const ConfirmationModal: FC = () => {
  const { t } = useTranslation()
  const {
    confirmation: {
      isVisible,
      title,
      subtitle,
      text,
      cancelText,
      confirmText,
      iconName = 'report_problem',
      className,
      onCancel,
      onConfirm,
    },
    hideConfirmation,
  } = useUi()

  const handleCancel = () => {
    onCancel && onCancel()
    hideConfirmation()
  }

  const handleConfirm = async () => {
    onConfirm && onConfirm()
    hideConfirmation()
  }

  return (
    <Modal
      show={isVisible}
      onHide={hideConfirmation}
      className={classNames('ConfirmationModal', className)}
      backdrop="static"
      backdropClassName="ConfirmationBackdrop"
    >
      <Modal.Header className="justify-content-around">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row w-100">
          {iconName && (
            <div className={`col-12 text-center ${iconName}`}>
              <Icon name={iconName} className="icon--disabled" />
            </div>
          )}
          <div className="col-12 text-center content">
            {subtitle && <h6>{subtitle}</h6>}
            {typeof text === 'string' ? (
              <p className="ConfirmationModal__text">{text}</p>
            ) : (
              text
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {cancelText && (
          <button className="btn btn-outline-primary" onClick={handleCancel}>
            {cancelText || t('common.cancel')}
          </button>
        )}
        <button
          className={
            !cancelText ? 'btn btn-primary btn-only' : 'btn btn-primary'
          }
          onClick={handleConfirm}
        >
          {confirmText || t('common.accept')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
