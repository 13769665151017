import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, FormTextInput } from '@liveconnect/components'

import useNotifications from '../../../utils/notifications/useNotifications'
import { buildValidationSchema } from './validations'
import useTenants from '../../../core/tenants/useTenants'
import { CreateTenantForm } from '../../../core/tenants/types'

interface CreateTenantProps {
  onClose: () => void
}

const CreateTenant: FC<CreateTenantProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const notify = useNotifications()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { createTenant, fetchList } = useTenants()

  const methods = useForm<CreateTenantForm>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods

  const onSubmit = async (data: CreateTenantForm) => {
    setIsSubmitting(true)
    try {
      await createTenant(data)
      await fetchList()
      notify.success(t('tenant.create.success'))
      onClose()
    } catch (error: unknown) {
      notify.error(t('tenant.create.error'))
      onClose()
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      className="CreateTenant"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('tenant.create.title')}</Modal.Title>
      </Modal.Header>
      <FormProvider methods={methods}>
        <Modal.Body>
          <div className="d-flex mb-3">
            <div className="bd-highlight">{t('common.required')}</div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormTextInput
                control={control}
                name="name"
                label={t('tenant.create.name.label')}
                placeholder={t('tenant.create.name.placeholder')}
                type="text"
                required={true}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <FormTextInput
                  control={control}
                  name="slug"
                  label={t('tenant.create.slug.label')}
                  placeholder={t('tenant.create.slug.placeholder')}
                  type="text"
                  required={true}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={onClose}>
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
          >
            {t('common.add')}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default CreateTenant
