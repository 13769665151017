import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabInternal, TabsInternal } from '@liveconnect/components'

import { Main } from '../../components/Main'
import EmailLicense from './Email'
import CometchatLicenseForm from './Cometchat'
import useLicenses from '../../core/licenses/useLicenses'

const LicensesScreen: FC = () => {
  const { t } = useTranslation()
  const [tabs, setTabs] = useState<TabInternal[]>([])
  const { clearLicenses } = useLicenses()

  useEffect(() => {
    setTabs([
      {
        id: 'email',
        label: t('licenses.tabs.email'),
        component: <EmailLicense />,
      },
      {
        id: 'cometchat',
        label: t('licenses.tabs.cometchat'),
        component: <CometchatLicenseForm />,
      },
    ])
    return clearLicenses
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="LicensesScreen">
      <h1 className="h3">{t('licenses.title')}</h1>
      <p>{t('licenses.hint')}</p>
      <TabsInternal tabs={tabs} />
    </Main>
  )
}

export default LicensesScreen
