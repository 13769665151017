import { NavbarItem } from './types'

export const tenantItems: NavbarItem[] = [
  {
    key: 'tenants',
    trnsKey: 'navMenu.tenants',
    icon: 'menu_tenants',
    path: '/tenants',
    exact: true,
    disabled: false,
    permissions: [],
  },
  {
    key: 'tenant',
    trnsKey: 'navMenu.tenant',
    icon: 'menu_tenant',
    path: '/tenants/:tenantId',
    exact: true,
    disabled: false,
    permissions: [],
  },
  {
    key: 'modules',
    trnsKey: 'navMenu.modules',
    icon: 'menu_modules',
    path: '/tenants/:tenantId/modules',
    exact: true,
    disabled: false,
    permissions: [],
  },
  {
    key: 'url',
    trnsKey: 'navMenu.url',
    icon: 'menu_url',
    path: '/tenants/:tenantId/urls',
    exact: true,
    disabled: false,
    permissions: [],
  },
  {
    key: 'ids',
    trnsKey: 'navMenu.ids',
    icon: 'menu_ids',
    path: '/tenants/:tenantId/ids-config',
    exact: true,
    disabled: false,
    permissions: [],
  },
  {
    key: 'licenses',
    trnsKey: 'navMenu.licenses',
    icon: 'menu_licenses',
    path: '/tenants/:tenantId/licenses',
    exact: true,
    disabled: false,
    permissions: [],
  },
  {
    key: 'admins',
    trnsKey: 'navMenu.admins',
    icon: 'menu_admins',
    path: '/tenants/:tenantId/admins',
    exact: true,
    disabled: false,
    permissions: [],
  },
]
